import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/PatientsHomepage';

export const patientHomepageData = () => {
  const data = useStaticQuery(graphql`
    query patientHomepageQuery {
      dGraphQl {
        nodeQuery(
          filter: {
            conjunction: AND,
            conditions: [
              {field: "title", value: "Home"},
              {field: "type", value: "patient_page"}
            ]
          }
        ) {
          entities {
            ... on DrupalGraphQl_NodePatientPage {
              entityBundle
              entityLabel
              uuid
              fieldHeaderMenu {
                entity {
                  name
                }
              }
              fieldBannerTop {
                entity {
                  ... on DrupalGraphQl_ParagraphBackgroundVideo {
                    fieldVideo {
                      entity {
                        url
                      }
                    }
                    fieldBannerText {
                      value
                    }
                    uuid
                  }
                }
              }
              fieldContent {
                entity {
                  ... on DrupalGraphQl_ParagraphMultiColumns {
                    fieldIcon {
                      title
                      alt
                      entity {
                        url
                        uuid
                      }
                    }
                    uuid
                    fieldLink {
                      title
                      url {
                        path
                      }
                    }
                    fieldNtroText
                  }
                }
              }
              fieldMetatags {
                entity {
                  ... on DrupalGraphQl_ParagraphMetatags {
                    fieldDescription
                    fieldKeywords
                  }
                }
              }
              fieldMetaTitle
              fieldHreflang {
                entity {
                  ...ParagraphHreflang
                }
              }
            }
          }
        }
      }
    }
  `);

  return transformers.patientHomepageTransform(data.dGraphQl);
}


